<template>
  <table-view
    :total='total'
    :query-info.sync='queryInfo'
    @reload-table='renderTable'
  >
    <template #header>
      <view-search-form
        ref='searchFormRef'
        :queryInfo.sync='queryInfo'
        :tool-list="['grade', 'college', 'major','keyword']"
        :insert-select-all="[ 'college', 'major']"
        @on-search='renderTable(1)'/>
      <div class="header-button">
        <upload-excel :permit-key="['class:manage:edit']"
                      :loading.sync="loading"
                      :api="$http.importExcelHttp.importClasses"
                      @on-download="$http.exportExcelHttp.exportClassesTemplate()"
                      @on-success="renderTable(1)" />
        <el-button v-permission="['class:manage:edit']" type="primary" size="small" @click="showDialog()">添加班级</el-button>
      </div>
    </template>
    <!-- body -->
      <el-table :data="tableData" style="width: 100%" border stripe>
        <el-table-column type="index" label="ID" width="40"/>
        <el-table-column prop="grade" label="年级" width="60"/>
        <el-table-column prop="collegeName" label="所属院系" width="160"/>
        <el-table-column prop="majorName" label="专业" width="200"/>
        <el-table-column prop="className" label="班级名称" width="200"/>
        <el-table-column prop="stuNum" label="班级人数" width="80"/>
        <el-table-column prop="managerUserName" label="辅导员" width="100"/>
        <el-table-column prop="managerUserPhone" label="辅导员电话" width="150"/>
        <el-table-column prop="remarks" label="备注"/>
        <el-table-column label="操作" width="160" fixed="right">
          <template v-slot="{row}">
            <el-button
              v-permission="['class:manage:edit']"
              type="primary"
              size="mini"
              @click="showDialog(row.id)"
            >编辑</el-button>
            <el-button
              v-permission="['class:manage:del']"
              type="danger"
              size="mini"
              @click="del(deleteClasses,row.id,`班级-${row.className}`)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    <addClass ref='dialogRef' :visible.sync="visibleDialog" v-if='visibleDialog' @on-close="renderTable"/>
  </table-view>
</template>

<script>
import { getClassesList, deleteClasses } from '@/api/classes'
import addClass from './dialog/addClasses.vue'
import tableView from '@/vue/mixins/table-view'

export default {
  components: {
    addClass
  },
  mixins: [tableView],
  data () {
    return {
      queryInfo: {
        grade: new Date().getFullYear(),
        collegeId: '',
        majorId: '',
        campusId: ''
      },
      classroomList: []
    }
  },
  methods: {
    deleteClasses, // 删除api
    // 获取班级列表
    renderTable (pageNum) {
      this.getTableData(getClassesList, pageNum)
    }
  }
}
</script>
