import request from '@/service/request'

/**
 * 用户接口
 */
// 获取用户列表
export const getUserList = (params) => {
  return request.post('/sys/user/page', params)
}

// 获取全部用户
export const getUserAll = (params) => {
  return request.post('/sys/user/list', params)
}
/* 获取住宿的教师
* @params {string} keyword
*/
export const listDormUserApi = (params) => {
  return request.post('/sys/user/listDormTeacher', params)
}
// 获取维修人员
export const getRepairWorkersApi = () => {
  return request.post('/dormitory/dormitoryRepair/getRepairWorkerList')
}

// 添加用户
export const addUser = (params) => {
  return request.post('/sys/user/save', params)
}

// 根据id获取用户信息
export const getUserInfoById = (params) => {
  return request.post('/sys/user/getById/' + params)
}

// 根据id删除用户
export const deleteUserById = (params) => {
  return request.post('/sys/user/del/' + params)
}

// 根据id获取角色列表
export const getRoleListById = (params) => {
  return request.post('/sys/user/listRoleIdByUserId/' + params)
}

// 分配用户角色
export const setUserRole = (params) => {
  return request.post('/sys/user/saveUserRole', params)
}

/* 用户管理 批量删除
* ids {Array} 要删除的行id
* */
export const batchDeleteUsersApi = (params) => {
  return request.post('/sys/user/delList', params)
}

/* 初始化密码
*  初始化后的密码为 nfxy14265
* */
export const resetPasswordApi = (id) => {
  return request.post(`/sys/user/initPassword/${id}`)
}

/** 修改教职工照片
 * @params {string} userId 教职工id
 * @params {string} headshot 教职工照片(注意小写)
 */
export const updateTeacherHeadshot = (params) => {
  return request.post('/sys/user/updateSysUserHeadshotInfo', params)
}
