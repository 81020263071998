var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"addClasses-dialog",attrs:{"title":(_vm.preTitle + "班级"),"visible":_vm.show,"close-on-click-modal":false,"width":"540px","before-close":_vm.cancel},on:{"update:visible":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('cancel-popover',{ref:"cancelRef",attrs:{"disabled":_vm.loadData.loading,"update-flag":_vm.updateFlag},on:{"update:disabled":function($event){return _vm.$set(_vm.loadData, "loading", $event)}}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.save('班级', _vm.addClasses)}}},[_vm._v("确 定")])]},proxy:true}])},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"rules":_vm.rules,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"年级","prop":"grade"}},[_c('el-select',{on:{"change":_vm.changeGrade},model:{value:(_vm.formData.grade),callback:function ($$v) {_vm.$set(_vm.formData, "grade", $$v)},expression:"formData.grade"}},_vm._l((_vm.$store.state.systemParam.grade),function(ref){
var paramValue = ref.paramValue;
return _c('el-option',{key:paramValue,attrs:{"label":paramValue,"value":paramValue}})}),1)],1),_c('el-form-item',{attrs:{"label":"所属院系","prop":"collegeId"}},[_c('tool-tips-group',{attrs:{"is-flex":"","tips-content":"选择专业后，会自动获得学院名"}},[_c('el-select',{attrs:{"disabled":""},model:{value:(_vm.formData.collegeId),callback:function ($$v) {_vm.$set(_vm.formData, "collegeId", $$v)},expression:"formData.collegeId"}},_vm._l((_vm.collegeList),function(ref){
var collegeName = ref.collegeName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":collegeName,"value":id}})}),1)],1)],1),_c('el-form-item',{attrs:{"label":"专业","prop":"majorId"}},[_c('el-select',{attrs:{"filterable":"","placeholder":"根据年级加载专业列表"},on:{"change":_vm.changeMajor},model:{value:(_vm.formData.majorId),callback:function ($$v) {_vm.$set(_vm.formData, "majorId", $$v)},expression:"formData.majorId"}},_vm._l((_vm.majorList),function(ref){
var majorName = ref.majorName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":majorName,"value":id}})}),1)],1),_c('el-form-item',{attrs:{"label":"班级名称","prop":"className"}},[_c('el-input',{model:{value:(_vm.formData.className),callback:function ($$v) {_vm.$set(_vm.formData, "className", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.className"}})],1),_c('el-form-item',{attrs:{"label":"选择辅导员"}},[_c('el-select',{attrs:{"clearable":"","placeholder":"选择辅导员","loading":_vm.listSysUserByRealNameLoading},on:{"change":_vm.handleChangeManagerUser},model:{value:(_vm.formData.managerUserId),callback:function ($$v) {_vm.$set(_vm.formData, "managerUserId", $$v)},expression:"formData.managerUserId"}},_vm._l((_vm.managerList),function(ref){
var realName = ref.realName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":realName,"value":id}})}),1)],1),_c('el-form-item',{attrs:{"label":"辅导员电话","prop":"managerUserPhone"}},[_c('el-input',{model:{value:(_vm.formData.managerUserPhone),callback:function ($$v) {_vm.$set(_vm.formData, "managerUserPhone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.managerUserPhone"}})],1),_c('el-form-item',{attrs:{"label":"备注","prop":"remarks"}},[_c('el-input',{model:{value:(_vm.formData.remarks),callback:function ($$v) {_vm.$set(_vm.formData, "remarks", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.remarks"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }